import { render, staticRenderFns } from "./payment-form.vue?vue&type=template&id=be80a702&scoped=true"
import script from "./payment-form.vue?vue&type=script&lang=ts"
export * from "./payment-form.vue?vue&type=script&lang=ts"
import style0 from "./payment-form.pcss?vue&type=style&index=0&id=be80a702&prod&lang=postcss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be80a702",
  null
  
)

export default component.exports