
// Packages
import Vue, { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { datadogRum } from '@datadog/browser-rum';

// Types
import type { TranslateResult } from 'vue-i18n';

// Components
import Button from '../button/button.vue';
import Phone from '../phone/phone.vue';
import MarketingPreferences from '../marketing-preferences/marketing-preferences.vue';
import TextField from '../text-field/text-field.vue';

// Helpers
import {
  notNumber,
  required,
  alphaNumSpace,
  maxLength,
  alphaNumSpaceOrEmpty,
  onlyNumber,
  minLength,
  requiredIf
} from '@white-label-helper/vuelidate';
import type { UserPayload } from '@white-label-helper/api-parkings-user';
import { getAppHeroProduct } from '@white-label-helper/get-app-hero-product';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { getAppColor } from '@white-label-helper/get-app-color';

// Stores
import { useUserInfoStore } from '@white-label-store/user-info';
import { useMarketingPreferencesAccountStore } from '@white-label-store/marketing-preferences-account';

// Mixins
import auth from '@white-label-helper/mixin-auth';

export default defineComponent({
  name: 'ProfileSettings',

  components: {
    givenName: TextField,
    familyName: TextField,
    VehicleLicensePlate: TextField,
    VehicleMake: TextField,
    VehicleModel: TextField,
    VehicleColor: TextField,
    PostalCode: TextField,
    PhoneField: Phone,
    UserPassword: Button,
    CancelButton: Button,
    SaveButton: Button,
    MarketingPreferences,
  },

  mixins: [auth],

  data() {
    return {
      userData: {
        givenName: '',
        familyName: '',
        registrationNumber: '',
        vehicleMake: '',
        vehicleModel: '',
        vehicleColor: '',
        postalCode: '',
        phoneData: {
          phone_number: '',
          formatted_number: '',
          country_code: '',
          country_iso_code: '',
        },
        marketingChannels: {
          email: false,
          sms: false,
        } as { email?: boolean; sms?: boolean },
      },
      hardCopy: '',
      saveLoading: false,
      userDataLoading: false,
      marketingSettingsLoading: true,
      channelId: 0,
      showMarketingChannels: true,
    };
  },

  validations() {
    return {
      userData: {
        givenName: {
          required,
          notNumber,
        },

        familyName: {
          required,
          notNumber,
        },

        registrationNumber: {
          required: requiredIf(() => {
            return this.defaultHeroProduct === 'parking';
          }),
          alphaNumSpace(val: string) {
            // Fix when an empty field is considered an error
            // We have a separate validation to check if this field is required
            if (val) {
              return alphaNumSpace(val);
            }
            return true;
          },
          maxLength: maxLength(25),
        },

        postalCode: {
          alphaNumSpaceOrEmpty,
        },

        phoneData: {
          phone_number: {
            onlyNumber,
            minLength: minLength(10),
            maxLength: maxLength(11),
          },
        },
      },
    }
  },

  computed: {
    ...mapState(useUserInfoStore, {
      getAllUserDetails: 'readUserDetails',
      getPhoneNumbers: 'readPhoneNumbers',
      getPostalCode: 'readPostalCode',
      getVehicleDetails: 'readVehicleDetails',
    }),

    ...mapState(useMarketingPreferencesAccountStore, {
      readMarketingChannels: 'readMarketingChannels',
    }),


    defaultHeroProduct: () => {
      return getAppHeroProduct('parking')
    },
    /**
     * An object of customer error messages to display for the first name field.
     * Key is the error code that needs to be the same as what vuelidate used with the value being the message
     */
    givenNameErrorMessage(): {
      required: TranslateResult;
      notNumber: TranslateResult;
    } {
      return {
        required: this.$t('UI.formErrors.firstname.required'),
        notNumber: this.$t('UI.formErrors.firstname.notNumber'),
      };
    },

    /**
     * An object of customer error messages to display for the last name field.
     * Key is the error code that needs to be the same as what vuelidate used with the value being the message
     */
    familyNameErrorMessage(): {
      required: TranslateResult;
      notNumber: TranslateResult;
    } {
      return {
        required: this.$t('UI.formErrors.lastname.required'),
        notNumber: this.$t('UI.formErrors.lastname.notNumber'),
      };
    },

    /**
     * An object of custom error messages to display for the vehicle license plate field.
     * Key is the error code that needs to be the same as what vuelidate used with the value being the message
     */
    licensePlateNumberMessage(): {
      required: TranslateResult;
      alphaNumSpace: TranslateResult;
      maxLength: TranslateResult;
    } {
      return {
        required: this.$t('UI.formErrors.licensePlateNumber.required'),
        alphaNumSpace: this.$t(
          'UI.formErrors.licensePlateNumber.alphaNumSpace'
        ),
        maxLength: this.$t('UI.formErrors.licensePlateNumber.maxLength', {
          number: 25,
        }),
      };
    },

    /**
     * An object of custom error messages to display for the postal code field.
     * Key is the error code that needs to be the same as what vuelidate used with the value being the message
     */
    postalCodeErrorMessage(): { alphaNumSpaceOrEmpty: TranslateResult } {
      return {
        alphaNumSpaceOrEmpty: this.$t(
          'UI.formErrors.zipCode.alphaNumSpaceOrEmpty'
        ),
      };
    },

    /** Returns the user first name */
    valueFirstName() {
      return this.getAllUserDetails.firstName;
    },

    /** Returns the user last name */
    valueLastName() {
      return this.getAllUserDetails.lastName;
    },

    /**
     * Returns a list of all the channels that have been enabled by the partner
     */
    marketingChannels() {
      return this.readMarketingChannels;
    },

    /** Returns the payload needed to update the user's profile data */
    userPayload(): UserPayload {
      const { userData } = this;
      const payload: UserPayload = {
        channel_id: this.channelId,
        first_name: userData.givenName,
        last_name: userData.familyName,
        phone: {
          phone_number: userData.phoneData.phone_number,
          formatted_number: userData.phoneData.formatted_number,
          country_code: userData.phoneData.country_code,
          country_iso_code: userData.phoneData.country_iso_code,
        },
        zipcode: userData.postalCode,
      };

      if (this.defaultHeroProduct === 'parking') {
        payload.vehicles = [
          {
            registration_number: userData.registrationNumber,
            make: userData.vehicleMake,
            model: userData.vehicleModel,
            colour: userData.vehicleColor,
          }
        ]
      }

      if (
        Array.isArray(this.marketingChannels) &&
        this.marketingChannels.length > 0
      ) {
        payload.marketing_channels = {};

        if (this.marketingChannels.includes('email')) {
          payload.marketing_channels.email = userData.marketingChannels.email;
        }

        if (this.marketingChannels.includes('sms')) {
          payload.marketing_channels.sms = userData.marketingChannels.sms;
        }
      }

      return payload;
    },

    /** Enable the cancel button if the form has been edited */
    disableCancelButton(): boolean {
      return Object.is(JSON.stringify(this.userData), this.hardCopy);
    },

    /**
     * Display the customer name either form userInfo store or Auth0 if it's a new customer, and we don't have the name
     */
    displayUserName(): string | boolean {
      const name =
        this.valueFirstName.length > 0
          ? this.valueFirstName.concat(' ', this.valueLastName)
          : this.auth0User?.name;

      return typeof name === 'string' && name.toUpperCase();
    },
  },

  async mounted() {
    await this.getUserData();
    await this.getMarketingPreferences();
  },

  methods: {
    ...mapActions(useUserInfoStore, {
      changeUserPassword: 'changeUserPassword',
      fetchUserDetails: 'fetchUserDetails',
      updateUserDetails: 'updateUserDetails',
    }),

    ...mapActions(useMarketingPreferencesAccountStore, {
      dispatchMarketingPreferences: 'dispatchMarketingPreferences',
    }),


    /** Resets the form values form the userInfo store */
    resetForm(): void {
      this.userData = JSON.parse(this.hardCopy);
    },

    /** Display the success message when the user updates their data. */
    displaySuccessMessage(): void {
      this.$openNotification(this.$t('UI.notifications.settingsUpdated'));
    },

    /**
     * Update the user store with the updated values for marketing preferences
     */
    updateMarketingPreferences(payload: {
      channel: 'email' | 'sms';
      subscribed: boolean;
    }) {
      Vue.set(
        this.userData.marketingChannels,
        payload.channel,
        payload.subscribed
      );
    },

    async getMarketingPreferences(): Promise<void> {
      await this.dispatchMarketingPreferences(this.$store);

      this.marketingSettingsLoading = false;
      this.showMarketingChannels =
        !!this.marketingChannels?.includes('email') ||
        !!this.marketingChannels?.includes('sms');
    },

    /** API call to get the user data */
    async getUserData(): Promise<void> {
      if (this.tokenIsValid) {
        this.userDataLoading = true;

        const token = this.getToken;

        await this.fetchUserDetails(token)
          .then((data) => {
            this.channelId = data.channel_id;
            this.userData = {
              givenName: this.getAllUserDetails.firstName,
              familyName: this.getAllUserDetails.lastName,
              registrationNumber: this.getVehicleDetails.registrationNumber,
              vehicleMake: this.getVehicleDetails.make,
              vehicleModel: this.getVehicleDetails.model,
              vehicleColor: this.getVehicleDetails.colour,
              postalCode: this.getPostalCode,
              phoneData: {
                phone_number: this.getPhoneNumbers.phoneNumber,
                formatted_number:
                  this.getPhoneNumbers.phoneNumberWithDialingCode,
                country_code: this.getPhoneNumbers.dialingCode,
                country_iso_code: this.getPhoneNumbers.isoCode,
              },
              marketingChannels: { ...this.getMarketingPreferences },
            };

            // Store a version of the data in string format for quick compression. This shouldn't be updated
            this.hardCopy = JSON.stringify(this.userData);
          })
          .catch((error: Error) => {
            datadogRum.addError(error);
          })
          .finally(() => {
            this.userDataLoading = false;
          });
      } else {
        this.logout();
      }
    },

    /** API call to update the user data */
    async updateUserData(): Promise<void> {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.invalidForm();
      } else {
        this.saveLoading = true;
        try {
          const payload = this.userPayload;
          const bearerToken = this.getToken;

          await this.updateUserDetails({ payload, bearerToken });
          // Store a version of the data in string format for quick compression. This shouldn't be updated
          this.hardCopy = JSON.stringify(this.userData);
          this.displaySuccessMessage();
        } catch (error: any) {
          this.openTechnicalIssueModal();
          datadogRum.addError(error);
        }
        this.saveLoading = false;
      }
    },

    /** API call to get change password Auth0 redirect URL */
    async changePassword(): Promise<void> {
      try {
        const bearerToken = this.getToken;
        const passwordUpdateUrl = await this.changeUserPassword(bearerToken);

        const customPasswordUpdateUrl = this.getCustomChangePasswordUrl(passwordUpdateUrl);

        localStorage.setItem('passwordUpdateUrl', customPasswordUpdateUrl);
        this.logout();
      } catch (error: any) {
        this.openTechnicalIssueModal();
        datadogRum.addError(error);
      }
    },

    getCustomChangePasswordUrl(passwordUpdateUrl: string): string {
      const url = new URL(passwordUpdateUrl);
      const params = new URLSearchParams(url.search);

      // Add URL parameters needed for password update Auth0 screen customization
      params.append('logo', getAppVariable('logo'));
      params.append('primaryColor', getAppColor('brand[100]'));
      params.append('pageBackground', getAppColor('brand[16]'));
      url.search = params.toString();

      return url.toString();
    },

    /** If there are any error sending or receiving so modal */
    openTechnicalIssueModal(): void {
      this.$openModal('GlobalModalError', {
        header: this.$t('shared.modals.errors.technicalIssue.header'),
        body: this.$t('shared.modals.errors.technicalIssue.body'),
        btnText: this.$t('shared.buttons.tryAgain'),
        btnType: 'custom',
      });
    },

    /** If the form has any validation errors when trying to submit show modal saying so */
    invalidForm(): void {
      this.$openModal('GlobalModalError', {
        header: this.$t('shared.modals.errors.formHasErrors.header'),
        body: this.$t('shared.modals.errors.formHasErrors.body'),
        btnText: this.$t('shared.buttons.tryAgain'),
        btnType: 'custom',
      });
    },
  },
});
